<template>
    <div class="d-flex justify-content-between">
        <div v-if="slots.leftIcons" class="text-truncate">
            <div class="d-inline" style="margin-inline: -0.25rem">
                <slot name="leftIcons"></slot>
            </div>
            <slot></slot>
        </div>
        <span v-else class="text-truncate">
            <slot></slot>
        </span>
        
        <div v-if="slots.rightIcons">
            <slot name="rightIcons"></slot>
        </div>
        <Icon v-else :iconClass="iconClass" :iconTarget="iconTarget" :iconCallback="iconCallback" :title="iconTitle"/>
    </div>
</template>

<script setup lang="ts">
    import { useSlots } from "vue";
    import Icon from "contract.vue.components.ActionIcon.vue";

    const props = defineProps({
        iconClass: {
            type: String,
            required: false
        },

        iconTarget: {
            type: String,
            required: false
        },

        iconCallback: {
            type: Function,
            required: false
        },

        iconTitle: {
            type: String,
            required: false
        }
    });

    const slots = useSlots();

    /* 
    <!-- Usage example: -->

    <!-- To open a modal by click on the icon: -->
    import CellWithIcon from "contract.vue.components.CellWithIcon.vue";
    <OColumn ... v-slot="{ row }">
        <CellWithIcon iconTarget="ModalNameToOpen" iconClass="bi-pencil-square">
            {{ text to show }}
        </CellWithIcon>
    </OColumn>


    <!-- To call a function by click on the icon: -->
    import CellWithIcon from "contract.vue.components.CellWithIcon.vue";
    <OColumn ... v-slot="{ row }">
        <CellWithIcon iconCallback="myFunctionToCall" iconClass="bi-pencil-square">
            {{ text to show }}
        </CellWithIcon>
    </OColumn>


    <!-- Use multiple icons: -->
    import CellWithIcon from "contract.vue.components.CellWithIcon.vue";
    import Icon from "contract.vue.components.ActionIcon.vue";

    <OColumn ... v-slot="{ row }">
        <CellWithIcon>
            <template #leftIcons>
                <Icon iconClass="bi-arrows-angle-expand" iconTarget="ModalNameToOpen"/>
                <Icon iconClass="bi-three-dots" :iconCallback="() => console.log('icon callback')"/>
            </template>

            {{ text to show }}

            <template #rightIcons>
                <Icon iconClass="bi-x-lg" :iconCallback="() => console.log('icon callback')"/>
            </template>
        </CellWithIcon>
    </OColumn>
    */
</script>